<template>
  <div class="container container_caseList">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">搜索结果</div>
    </div>
    <div class="pageTitle">搜索结果</div>
    <!-- 结果为空时显示 -->
    <div class="empty" v-if="resultList.length == 0">
      <img src="@/assets/images/search_empty.png" alt="" />
    </div>
    <!-- 结果不为空时显示 -->
    <div class="floor" v-if="resultList.length != 0">
      <div class="listWrap">
        <div
          class="list"
          :style="{ justifyContent: total <= 3 ? 'flex-start' : '' }"
        >
          <div
            class="item item_hover"
            @click="goDetails(item.type, item.id,item.plate_id)"
            v-for="item in resultList"
            :key="item.id"
          >
            <img class="caseImg" :src="item.image_file.obs_path" alt="" />
            <div class="caseInfo">
              <div class="intro">
                {{ item.title }}
              </div>
              <div class="tags">
                <div class="tag" v-for="_tags in item.tags" :key="_tags.id">
                  {{ _tags.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block paginationWrap" v-if="resultList.length != 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="16"
        layout="total, prev, pager, next"
        :total="total"
        :small="size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      searchTitle: '', // 搜索的文字
      size: false,
      currentPage4: 1,
      resultList: [],
      total: 0,
      plate_id:'',
      page:1
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
    }
    // let bottom = document.getElementsByClassName('bottom')[0]
    // bottom.style.display = 'block'
  },
  created() {
    let searchTitle = this.$route.query.title
    this.searchRes(searchTitle)
  },
  methods: {
    // 获取搜索结果页面
    async searchRes(searchTitle) {
      let { data: res } = await this.$http.get('/search-article', {
        params: { title: searchTitle,limit:16,page:this.page }
      })
      if (res.state == 200) {
        this.resultList = res.data.data
        this.total = res.data.total
        this.searchTitle = searchTitle
        console.log(searchTitle)

      }
    },
    // 返回
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    // 翻页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.page = val
      this.searchRes(this.searchTitle)
    },
    // 进入文章详情页面
    goDetails(type, id,plate_id) {
      if (type == 1) {
        this.$router.push(`/details?detailsId=${id}&plate_id=${plate_id}`)
      } else if (type == 2) {
        this.$router.push(`/video?detailsId=${id}&plate_id=${plate_id}`)
      }
    }
  }
}
</script>

<style lang="less">
body {
  background: #f7f6fb;
  width: 100%;
  height: 100%;
}
.el-pager li,
.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination button:disabled {
  background: none;
}

.navBar_m {
  display: none;
}
.el-pagination {
  display: flex;
  justify-content: center;
}
.paginationWrap {
  margin-top: 20px;
}
.pageTitle {
  width: 100%;
  // height: 40px;
  font-family: SourceHanSansSC-Bold;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 45px;
}
.container_caseList {
  width: 1180px;
  margin: 0 auto;
  background: #f7f6fb;
  .selectWrap {
    width: 100%;
    height: 123px;
    background-color: #ffffff;
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .listWrap {
    width: 100%;
    // height: 260px;
    // background-color: #ffffff;
    margin-top: 37px;
    .list {
      widows: 100%;
      height: 100%;
      display: flex;
      justify-content:flex-start;
      align-items: center;
      flex-wrap: wrap;
      .item {
        border-radius: 5px;
        height: 100%;
        width: 24%;
        overflow: hidden;
        cursor: pointer;
        margin-right: 15px;
        margin-bottom: 20px;
      }
      .item:nth-child(4n) {
        margin-right: 0;
      }
      .caseImg {
        display: block;
        width: 100%;
        height: 155px;
      }
      .caseInfo {
        width: 100%;
        // height: 105px;
        background: #fff;
        padding: 15px 10px 19px 10px;
      }
      .intro {
        width: 100%;
        height: 45px;
        // margin-top: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 10px;
      }
      .tags {
        width: 100%;
        height: 21px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        align-items: center;
        flex-wrap: wrap;
      }
      .tag {
        min-width: 25%;
        max-width: 30%;
        line-height: 19px;
        text-align: center;
        height: 100%;
        float: left;
        padding: 0px 4px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ff9d4e;
        border: 1px solid #ff9d4e;
        margin-right: 3px;
        box-sizing: border-box;
        border-radius: 5px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tag:nth-child(4) {
        margin-right: 0;
      }
    }
  }

  .title {
    width: 100%;
    height: 46px;
    margin-top: 40px;
    font-family: SourceHanSansSC-Bold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  .itemBanner {
    width: 100%;
    height: 274px;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  //   筛选
  .selectItem {
    width: 100%;
    height: 40px;
    padding: 0 19px;
    .label {
      width: 5%;
      float: left;
      font-family: SourceHanSansSC-Regular;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
    }
    .tags {
      width: 90%;
      float: left;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tag {
        padding: 0 4px;
        height: 26px;
        border-radius: 2px;
        font-family: SourceHanSansSC-Regular;
        font-size: 16px;
        line-height: 26px;
        color: #333333;
        cursor: pointer;
      }
      .active {
        background-color: #ffb11a;
        color: #fff;
      }
    }
  }
}
.empty {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 20%;
  }
}
@media screen and(max-width:768px) {
  // 隐藏顶部navBar
  // .navWrap {
  //   display: none;
  // }
  .empty {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 40%;
    }
  }
  .container_caseList {
    width: 100%;
    margin: 0 auto;
    .pageTitle {
      display: none;
    }
    // 显示移动端navBar
    .navBar_m {
      display: block;
      width: 100%;
      height: 46px;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      .leftIcon {
        width: 40px;
        height: 100%;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        position: relative;
        z-index: 6;
      }
      .navBarText_m {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-family: SourceHanSansSC-Regular;
        font-size: 18px;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .paginationWrap {
      margin-top: 0.2rem;
    }
    .pageTitle {
      width: 100%;
      // height: 0.4rem;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.3rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.4rem;
      letter-spacing: 0rem;
      color: #333333;
      margin-top: 0.45rem;
    }
    .selectWrap {
      width: 100%;
      height: 1.23rem;
      background-color: #ffffff;
      margin-top: 0.17rem;
      margin-bottom: 0.2rem;
    }

    .listWrap {
      width: 100%;
      height: auto;
      // background-color: #ffffff;
      margin-top: 0.37rem;
      .list {
        widows: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        .item {
          border-radius: 0.05rem;
          height: 100%;
          width: 48%;
          overflow: hidden;
          margin-top: 0.13rem;
          clear: both;
          margin-right: 0;
        }
        .item:nth-child(2n) {
          margin-left: 0.2133rem;
          margin-right: 0;
        }
        .caseImg {
          display: block;
          width: 100%;
          height: auto;
        }
        .caseInfo {
          width: 100%;
          height: auto;
          background: #fff;
          // padding: 0.07rem;
          padding: 0.15rem 0.1rem 0.19rem 0.1rem;
        }
        .intro {
          width: 100%;
          height: 45px;
          margin-top: 0.17rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 0.1rem;
        }
        .tags {
          width: 100%;
          height: 21px;
          justify-content: flex-start;
          overflow: hidden;
          align-items: center;
          flex-wrap: wrap;
        }
        .tag {
          min-width: 25%;
          max-width: 30%;
          padding: 0 2px;
          line-height: 18px;
          font-family: SourceHanSansCN-Regular;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ff9d4e;
          border: 0.01rem solid #ff9d4e;
          margin-right: 0.03rem;
          box-sizing: border-box;
          border-radius: 0.05rem;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .tag:nth-child(4) {
          margin-right: 0;
        }
      }
    }

    .title {
      width: 100%;
      height: 0.46rem;
      margin-top: 0.4rem;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.26rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #333333;
    }
    .itemBanner {
      width: 100%;
      height: 2.74rem;
      background-color: #ffffff;
      border-radius: 0.05rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    //   筛选
    .selectItem {
      width: 100%;
      height: 0.4rem;
      padding: 0 0.19rem;
      .label {
        width: auto;
        padding-right: 0.08rem;
        float: left;
        font-family: SourceHanSansSC-Regular;
        font-size: 0.26rem;
        line-height: 0.4rem;
        color: #333333;
      }
      .tags {
        width: 80%;
        float: left;
        height: 0.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tag {
          padding: 0 0.04rem;
          height: 0.26rem;
          border-radius: 0.02rem;
          font-family: SourceHanSansSC-Regular;
          font-size: 0.26rem;
          line-height: 0.26rem;
          color: #333333;
          cursor: pointer;
        }
        .active {
          background-color: #ffb11a;
          color: #fff;
        }
      }
    }
  }
}
</style>
